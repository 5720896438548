import { lazy, Suspense, useState } from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import styles from './App.module.css';
import NavbarB from './ui/NavbarBottom.js';
import Navbar from './ui/Navbar.js';
import Home from './components/Home';
import Footer from './components/Footer.js';
import Download from './components/Download.js';
import SellLand from './components/SellLand.js';
// const About = lazy(() => import('./components/About.js'));
import { Helmet } from 'react-helmet';

// Create a wrapper component to handle language based on route
function AppContent() {
  const location = useLocation();
  const [languageEn, setLanguageEn] = useState(location.pathname.startsWith('/en'));

  const handleLangChange = () => {
    const newLanguage = !languageEn;
    setLanguageEn(newLanguage);
    // Optionally redirect to the appropriate URL when language is changed
    if (newLanguage) {
      window.history.pushState({}, '', '/en' + location.pathname.replace('/en', ''));
    } else {
      window.history.pushState({}, '', location.pathname.replace('/en', ''));
    }
  };

  const title = languageEn 
    ? "Vkushty - Luxury Houses in Bistritsa, Sofia"
    : "Вкъщи - Луксозни къщи в Бистрица, София";

  const description = languageEn
    ? "Vkushty offers luxury houses in a gated community in Bistritsa, Sofia. Panoramic views, modern design, and excellent location."
    : "Вкъщи предлага луксозни къщи в затворен комплекс в Бистрица, София. Панорамна гледка, модерен дизайн и отлична локация.";

  return (
    <div className={styles.app}>
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
        <link rel="canonical" href={`https://vkushty.com${languageEn ? '/en' : ''}`} />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
      </Helmet>

      <header className={styles.header}>
        <Navbar languageEn={languageEn} handleLangChange={handleLangChange} />
        <NavbarB languageEn={languageEn} handleLangChange={handleLangChange} />
      </header>

      <div>
        <Suspense fallback={<div>Loading...</div>}>
          <Routes>
            <Route path="/" element={<Home languageEn={false} />} />
            <Route path="/en" element={<Home languageEn={true} />} />
            <Route path="/download" element={<Download />} />
            <Route path="/en/download" element={<Download languageEn={true} />} />
            <Route path="/parceli-i-zemi-prodajba" element={<SellLand english={false} />} />
            <Route path="/en/parceli-i-zemi-prodajba" element={<SellLand english={true} />} />
          </Routes>
        </Suspense>
      </div>

      <Footer />
    </div>
  );
}

// Main App component
function App() {
  return (
    <Router>
      <AppContent />
    </Router>
  );
}

export default App;
