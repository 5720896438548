import React, { useRef, useState, useEffect } from "react"
import styles from "./Navbar.module.css"
import { NavHashLink } from 'react-router-hash-link';
import logo from "../assets/graphics/VkushtY-2.png"
import { NavLink } from 'react-router-dom';
import { ReactComponent as BgFlag } from '../assets/icons/bg-flag.svg';
import { ReactComponent as EnFlag } from '../assets/icons/en-flag.svg';


export default function Navbar({ languageEn, handleLangChange }) {
  const [isSticky, setIsSticky] = useState(false);
  const headerRef = useRef(null);

  const text = {
    en: {
      button: "Contacts",
      slogan: ["Vkushty", "Join us at the peak of Sofia"],  // Split into array for line break
      number: "M +359 8777 1444 9",
    },
    bg: {
      button: "Контакти",
      slogan: ["Ела Вкъщи", "Eла на Върха"],  // Split into array for line break
      number: "M 08777 1444 9",
    }
  };

  const currentText = languageEn ? text.en : text.bg;

  useEffect(() => {
    const handleScroll = () => {
      if (headerRef.current && window.scrollY > (headerRef.current.offsetTop)) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);


  return (
    <div className={isSticky ? styles.navWrap : styles.navWrapNormal}
      ref={headerRef}>
      <div className={isSticky ? styles.content1 : styles.content}>
        <NavHashLink smooth className={styles.logoarea} to="/#top">
          <img className={styles.logo}
            alt="Vkushty logo"
            src={logo} />
          <p className={styles.slogan}>
           <b>{currentText.slogan[0]}</b> <br />{currentText.slogan[1]}
          </p>
        </NavHashLink>


        <div className={styles.logoarea}>
          <a href="tel:00359877714449" className={styles.phone}>
            {currentText.number}</a>

          <NavHashLink smooth className={styles.menuButton} to="/#contacts">
            {currentText.button}
          </NavHashLink>

          <NavLink 
            to={languageEn ? '/' : '/en'} 
            className={styles.langButton}
            onClick={handleLangChange}
          >
            {languageEn ? (
              <>
                <BgFlag className={styles.flagIcon} />
                <span>BG</span>
              </>
            ) : (
              <>
                <EnFlag className={styles.flagIcon} />
                <span>EN</span>
              </>
            )}
          </NavLink>
        </div>
      </div>
    </div>
  )
}