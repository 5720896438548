import React, { useRef } from "react"
import styles from "./NavbarBottom.module.css"
import { NavHashLink } from 'react-router-hash-link';
import { NavLink } from 'react-router-dom';
import logo from "../assets/graphics/VkushtY-2.png"
import imageIcon from "../assets/icons/images.png"
import mailIcon from "../assets/icons/email.png"
// import locIcon from "../assets/icons/location.png"
import phoneIcon from "../assets/icons/phone.png"
import { ReactComponent as BgFlag } from '../assets/icons/bg-flag.svg';
import { ReactComponent as EnFlag } from '../assets/icons/en-flag.svg';

export default function NavbarBottom({ languageEn, handleLangChange }) {
  const headerRef = useRef(null);

  const bulgarian = {
    button: "Контакти",
    slogan: "Ела Вкъщи, Ела на Върха",
    number: "M 08777 1444 9",
  }
  const english = {
    button: "Contacts",
    slogan: "Welcome Vkushty",
    number: "M +359 8777 1444 9",
  }

  return (
    <div className={styles.navWrapNormal}
      ref={headerRef}>

      <NavHashLink smooth className={styles.menuButton} to="/#top">
        <img className={styles.logo}
          alt="Vkushty logo"
          src={logo} />
      </NavHashLink>

      <NavHashLink smooth className={styles.menuButton} to="/#top">
        <img className={styles.icon}
          alt="gallery icon"
          src={imageIcon} />
      </NavHashLink>

      <a href="tel:00359877714449" className={styles.menuButton}>
        <img className={styles.icon}
          alt="Vkushty logo"
          src={phoneIcon} />
      </a>

      <NavHashLink smooth className={styles.menuButton} to="/#contacts">
        <img className={styles.icon}
          alt="message icon"
          src={mailIcon} />
      </NavHashLink>

      <NavLink 
        to={languageEn ? '/' : '/en'} 
        className={styles.langButton }
        onClick={handleLangChange}
      >
        {languageEn ? (
          <>
            <BgFlag className={styles.flagIcon} />
            <span>BG</span>
          </>
        ) : (
          <>
            <EnFlag className={styles.flagIcon} />
            <span>EN</span>
          </>
        )}
      </NavLink>

    </div>
  )
}