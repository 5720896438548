import React from "react"
import styles from "./Contact.module.css"
import axios from "axios"
import { baseUrl } from "../config.js"

export default function Contact({ languageEn }) {
  const [form, setForm] = React.useState({
    name: "",
    phone: '',
    email: '',
    message: '',
  })
  const [button, setButton] = React.useState('')
  const [error, setError] = React.useState('')

  const text = {
    en: {
      contact: "Contacts",
      phone: "Phone:",
      email: "Email:",
      phoneValue: "+359 8777 1444 9",
      nameLabel: "Your name*:",
      phoneLabel: "Your phone*:",
      messageLabel: "Message:",
      send: "Send",
      formTitle: "Contact Form",
      errors: {
        name: 'Please enter your name.',
        phone: 'Please enter your phone number.'
      },
      sending: 'Sending...',
      received: 'Received.'
    },
    bg: {
      contact: "Контакти",
      phone: "Телефон:",
      email: "Email:",
      phoneValue: "08777 1444 9",
      nameLabel: "Вашето име*:",
      phoneLabel: "Вашият телефон*:",
      messageLabel: "Съобщение:",
      send: "Изпрати",
      formTitle: "Формуляр за контакти",
      errors: {
        name: 'Ако обичате попълнете името си.',
        phone: 'Ако обичате попълнете телефонен номер.'
      },
      sending: 'Изпраща се',
      received: 'Получено.'
    }
  };

  const currentText = languageEn ? text.en : text.bg;

  React.useEffect(() => {
    setButton(currentText.send)
  }, [languageEn, currentText.send])

  function validateInput() {
    setButton(currentText.sending)
    if (form.name.length < 2) {
      setError(currentText.errors.name)
      return false
    }
    if (form.phone.length < 2) {
      setError(currentText.errors.phone)
      return false
    }
    return true;
  }

  async function handleSubmit(e) {
    e.preventDefault()
    const valid = validateInput();
    if (!valid) return

    try {
      const response = await axios.post(`${baseUrl}/homemessage`, form)

      if (response.status === 201) {
        setButton(currentText.received)
        setForm({
          name: "",
          number: 0,
          message: '',
          email: '',
        })
      }
    } catch (e) {
      console.log(e);
    }
  }

  function handleChange(e) {
    setForm((prev) => ({
      ...prev,
      [e.target.name]: e.target.value
    }))
  }

  return (
    <div className={styles.conWrap}>
      <hr />
      <h2 className={styles.h2}>
        {currentText.contact}</h2>

      <div className={styles.contactWrap}>
        <a className={styles.phoneNum}
          href={"tel:00359877714449"}>
          {currentText.phone} {currentText.phoneValue}
        </a>

        <a href="mailto:home@vkushty.com?subject=Посещение%20на%20място&body=Здравейте,%20искам%20да%20посетя%20обекта%20на:%20" 
           className={styles.phoneNum}>
          {currentText.email} home@vkushty.com
        </a>
      </div>

      <div className={styles.formWrap}>
        <h4 className={styles.h2}
          style={{ color: "white" }}>{currentText.formTitle}</h4>

        <form className={styles.contactForm}>
          <label>
            {currentText.nameLabel}
            <input type="text" maxLength={200} name="name"
              value={form.name} onChange={handleChange} />
          </label>
          <label>
            {currentText.phoneLabel}
            <input type="number" maxLength={200} name="phone"
              onChange={handleChange}
            />
          </label>
          <label>
            {currentText.email}
            <input type="email" maxLength={200} name="email"
              onChange={handleChange}
            />
          </label>

          <label>
            {currentText.messageLabel}
            <textarea maxLength={200} name="message"
              onChange={handleChange} />
          </label>

          <button
            className={styles.subButton}
            type="submit"
            onClick={handleSubmit}>
            {button}
          </button>
        </form>
        {error && <p>{error}</p>}
      </div>
    </div>
  )
}