import React, { useRef, useEffect, useMemo, useState } from "react";
import styles from "./Home.module.css";
import map from "../assets/cgi/map.webp";
import yard1 from "../assets/cgi/yard1-2.webp";
import yard1T from "../assets/cgi/yard1-2_thumb.webp";
import mapT from "../assets/cgi/map_thumb.webp";

import Contact from "../ui/Contact.js";
import Features from "./Features.js";
import { NavHashLink } from "react-router-hash-link";
import { Helmet } from "react-helmet";

import air1 from "../assets/cgi/Aerial_1_06-copy.jpeg";
import air1t from "../assets/cgi/Aerial_1_06-copy_thumb.webp";

import air2 from "../assets/cgi/Aerial_2_05-copy.jpeg";
import air2t from "../assets/cgi/Aerial_2_05-copy_thumb.webp";

import bed from "../assets/cgi/bedroom_4-copy.jpeg";
import bedt from "../assets/cgi/bedroom_4copy_thumb.webp";

import closeThumb from "../assets/cgi/closeUP_3C_thumb.webp";
import closeUp from "../assets/cgi/closeUP_3C.jpeg";

import livinDthumb from "../assets/cgi/livingroom_day_3_thumb.webp";
import livinD from "../assets/cgi/livingroom_day_3.webp";

import livinNt from "../assets/cgi/livingroom_night_3_thumb.webp";
import livinN from "../assets/cgi/livingroom_night_3.webp";
import birdseye from "../assets/photos/birdseye.JPG";
import birdseyeT from "../assets/photos/birdseye_thumb.JPG";

// Add these imports at the top of the file
import { ReactComponent as PlayIcon } from '../assets/icons/play.svg';
import { ReactComponent as PauseIcon } from '../assets/icons/pause.svg';

export default function Home({ languageEn }) {
  const isMounted = useRef(true);
  const [index, setIndex] = useState(0);
  const imagesArray = useMemo(
    () => [
      {
        src: closeUp,
        title: languageEn ? 'House Close Up' : 'Къщата от близо'
      },
      {
        src: birdseye,
        title: languageEn ? 'View over Sofia' : 'Гледката над София'
      },
      {
        src: air1,
        title: languageEn ? 'The Gated Community' : 'Затвореният комплекс'
      },
      {
        src: air2,
        title: languageEn ? 'Complex at Night' : 'Комплексът през нощта'
      },
      {
        src: livinD,
        title: languageEn ? 'Living Room' : 'Дневна стая'
      },
      {
        src: bed,
        title: languageEn ? 'Master Bedroom' : 'Главна спалня'
      },
      {
        src: livinN,
        title: languageEn ? 'Living Room with View' : 'Дневна с гледка'
      },
      {
        src: map,
        title: languageEn ? 'Project Location' : 'Локацията на обекта'
      },
    ],
    [languageEn]
  );
  const mobileArray = useMemo(
    () => [{
      src: closeUp,
      title: 'Къщата от близо'
    },
    {
      src: birdseye,
      title: 'Гледката над София'
    },
    {
      src: air1,
      title: 'Затвореният комплекс'
    },
    {
      src: air2,
      title: 'Комплексът през нощта'
    },
    {
      src: livinD,
      title: 'Дневна стая'
    },
    {
      src: bed,
      title: 'Главна спалня'
    },
    {
      src: livinN,
      title: 'Дневна с гледка'
    },
    {
      src: map,
      title: 'Локацията на обекта'
    },
  ],
    []
  );
  const thumbsArray = useMemo(
    () => [
      closeThumb,
      birdseyeT,
      air1t,
      air2t,
      livinDthumb,
      bedt,
      livinNt,
      mapT,
    ],
    []
  );

  const MOBILE_BREAKPOINT = 640;
  const isMobile = window.innerWidth <= MOBILE_BREAKPOINT ? true : false;

  const preload = new Image();
  preload.src = closeUp;

  const [imgsrc, setImgsrc] = useState(
    isMobile ? mobileArray[index] : imagesArray[index]
  );

  // Add a new state variable for pausing
  const [isPaused, setIsPaused] = useState(false);
  const [isFullscreen, setIsFullscreen] = useState(false);

  useEffect(() => {
    function setTimer1() {
      const timer = setInterval(() => {
        if (isMounted.current && !isPaused) {  // Add isPaused check here
          setIndex((prevIndex) => {
            const currentIndex =
              (prevIndex + 1) %
              (isMobile ? mobileArray.length : imagesArray.length);
            setImgsrc(
              isMobile ? mobileArray[currentIndex] : imagesArray[currentIndex]
            );
            return currentIndex;
          });
        }
      }, 5500);
      return () => clearInterval(timer);
    }

    isMounted.current = true;

    const cleanupTimer = setTimer1(); // setTimer1 now returns a cleanup function

    return () => {
      isMounted.current = false;
      cleanupTimer(); // Clear the interval
    };
  }, [imagesArray, isMobile, mobileArray, isPaused, languageEn]);  // Add languageEn to the dependency array

  const handleImageChange = (newIndex) => {
    isMobile
      ? setImgsrc(mobileArray[newIndex])
      : setImgsrc(imagesArray[newIndex]);
    setIndex(newIndex);
  };

  const handlePrev = () => {
    let prevIndex = isMobile
      ? (index - 1 + mobileArray.length) % mobileArray.length
      : (index - 1 + imagesArray.length) % imagesArray.length;
    handleImageChange(prevIndex);
  };

  const handleNext = () => {
    let nextIndex = isMobile
      ? (index + 1) % mobileArray.length
      : (index + 1) % imagesArray.length;
    handleImageChange(nextIndex);
  };

  function determineIndexClass(i) {
    if (i === index) return styles.thumbnailActive;
    return styles.thumbnail;
  }

  const title =
    "Вкъщи - Съвременен Затворен Комплекс от Просторни Къщи в Бистрица, гр. София.";
  const description =
    "Къщи в съвременно изпълнение за продажба, с високи тавани, панорамна гледка, широки пространства, прекрасно разположение и най-добрата локация в гр. София - ул. Изглед, Бистрица, 1444, ново строителство 2024г.";

  const titleEn =
    "Vkushty: luxury houses in a modern gated community on Izgled St., Bistritsa, Sofia, Bulgaria.";
  const descriptionEn =
    "Къщи в модерно изпълнение за продажба, с високи тавани, панорамна гледка, широки пространства, прекрасно разположение и най-добрата локация в гр. София - ул. Изглед, Бистрица, 1444, ново строителство 2024г.";

  // Modify the togglePause function to include the button text
  const togglePause = () => {
    setIsPaused(prev => !prev);
  };

  const toggleFullscreen = () => {
    setIsFullscreen(!isFullscreen);
  };

  const renderImageControls = () => (
    <>
      <button onClick={(e) => { e.stopPropagation(); togglePause(); }} className={styles.pauseButton}>
        {isPaused ? (
          <>
            <PlayIcon className={styles.icon} />
            <span className={styles.buttonText}>
              {languageEn ? "Resume" : "Продължи"}
            </span>
          </>
        ) : (
          <>
            <PauseIcon className={styles.icon} />
            <span className={styles.buttonText}>
              {languageEn ? "Pause" : "Пауза"}
            </span>
          </>
        )}
      </button>
      
      <button onClick={(e) => { e.stopPropagation(); handlePrev(); }} className={`${styles.navButton} ${styles.prevButton}`}>
        &#9664;
      </button>

      <button onClick={(e) => { e.stopPropagation(); handleNext(); }} className={`${styles.navButton} ${styles.nextButton}`}>
        &#9654;
      </button>

      <button onClick={(e) => { e.stopPropagation(); toggleFullscreen(); }} className={styles.fullscreenButton}>
        {isFullscreen ? (languageEn ? "Exit Fullscreen" : "Изход от Цял екран") : (languageEn ? "[ ] Fullscreen" : "[ ] Цял екран")}
      </button>
    </>
  );

  // Update the text objects to include all content
  const text = {
    en: {
      title: "Vkushty",
      slogan: "Join us at the top",
      description: "New contemporary houses with panoramic view over Sofia, in a gated community on Izgled street in Bistritsa.",
      welcomeText: "At the top of Izgled Street in Bistritsa, Sofia, with a unique panoramic view, your new home awaits you.",
      overview: "Overview",
      bistritsa: {
        title: "Bistritsa",
        content: "is one of the best places to live in Sofia due to its clean air, tranquility, and beautiful nature, just 5 minutes from the ring road."
      },
      izgledStreet: {
        title: 'Izgled Street',
        content: "in Bistritsa bears a name that perfectly reflects its essence. Located on a hill, it offers stunning panoramic views of all of Sofia from the east and north, while to the west and south you enjoy the nature of Vitosha mountain."
      },
      plot: {
        title: "The Plot",
        content: "where the Vkushty complex is located stands out with its strategic position. It offers not only impressive views in all directions but also an ideal balance between privacy and quick access to Sofia's urban environment."
      },
      complex: {
        title: "The Vkushty Complex",
        content: "is a gated community consisting of four contemporary houses. Each house has an area between 650 and 800 sq.m., spread over three levels. The project features super high ceilings, spacious rooms, and modern design emphasizing extensive glass surfaces. This ensures natural light and allows residents to enjoy the surrounding view from every corner of the home."
      },
      yourNewHome: {
        title: "Your New Home",
        content: "- each house is distributed over three levels, each designed with attention to detail and functionality.",
        features: [
          "Level -1 contains garages, service and wet rooms, as well as spacious wardrobes.",
          "The main floor (floor 1) is the heart of the home, designed for the family's active part of the day with a spacious living room, kitchen, office, and fitness area.",
          "On the second floor (floor 2) are the rest areas, including a master bedroom with a large dressing room and cozy children's rooms."
        ],
        additional: "Additionally, each house has a modern pool, its own photovoltaic panels located on the roof, on a plot of 920-980 sq.m., which provides the perfect space for outdoor entertainment."
      }
    },
    bg: {
      title: "Вкъщи",
      slogan: "Ела на Върха",
      description: "Нови просторни къщи с панорамна гледка над София, в затворен комплекс на ул. Изглед, Бистрица.",
      welcomeText: "На върха на ул. Изглед в Бистрица, София, с неповторима панормна гледка, ви очаква новият Ви дом.",
      overview: "Преглед",
      bistritsa: {
        title: "Бистрица",
        content: "е едно от най-страхотните места за живеене в София поради чистия въздух, спокойствие и красива природа, на едва 5 мин от околовръстното."
      },
      izgledStreet: {
        title: 'Улица "Изглед"',
        content: "в Бистрица носи име, което перфектно отразява нейната същност. Разположена на възвишение, тя предоставя зашеметяващи панорамни гледки към цяла София от изток и север, а на запад и юг се наслаждавате на природата на Витоша."
      },
      plot: {
        title: "Парцелът",
        content: "на който е разположен комплекс \"Вкъщи\", се отличава със своето стратегическо местоположение. Той предлага не само впечатляващи гледки във всички посоки, но и идеален баланс между уединение и бърз достъп до градската среда на София."
      },
      complex: {
        title: "Комплексът Вкъщи",
        content: "е от затворен тип състоящ се от четири съвременен къщи. Всяка къща е с площ между 650 и 800 кв.м., разпределена на три нива. Проектът се отличава със супер високи тавани, просторни стаи и съвременен дизайн, акцентиращ върху обширни стъклени повърхности. Това осигурява естествена светлина, но и позволява на обитателите да се насладят на заобикалящата ги гледка от всяко кътче на дома."
      },
      yourNewHome: {
        title: "Вашето Ново Вкъщи",
        content: "- всяка къща е разпределена на три нива, като всяко е проектирано с внимание към детайла и функционалността.",
        features: [
          "На ниво -1 се намират гаражи, сервизни и мокри помещения, както и просторни гардеробни.",
          "Основният етаж (етаж 1) е сърцето на дома, редназначен за активната част от деня на семейството с просторна дневна, кухня, офис и фитнес зона.",
          "На втория етаж (етаж 2) са разположени зоните за отдих, включително мастър спалня с голям дрешник и уютни детски стаи."
        ],
        additional: "Допълнително, всяка от къщите разполага със съвременен басейн, собствени фотоволтаични панели, разположени на покрива, върху парцел с площ 920-980 кв.м., който предоставя идеалното пространство за развлечения на открито."
      }
    }
  };

  // Use the current language to select text
  const currentText = languageEn ? text.en : text.bg;

  return (
    <div className={styles.hwrap}>
      <Helmet>
        <title>{title}</title>
        <meta property="og:title" content={title} />
        <meta name="twitter:title" content={title} />

        <meta name="description" content={description} />
        <meta property="og:description" content={description} />
        <meta name="twitter:description" content={description} />

        <link rel="canonical" href="https://vkushty.com" />
        <meta property="og:url" content="https://vkushty.com" />
        <meta property="og:image" content="https://vkushty.com/house1.webp" />
        <meta name="twitter:creator" content="@dvebene" />

        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "http://schema.org",
            "@type": "Organization",
            url: "https://www.vkushty.com",
            logo: "https://vkushty.com/VkushtY-2.png",
            sameAs: [
              "https://www.instagram.com/vkushty",
              "https://www.facebook.com/elanavurha",
            ],
          })}
        </script>
      </Helmet>

      <div className={styles.startWrap1}>
        <div className={styles.leftSec} id="top">

          {/* main display */}
          {imgsrc && (
            <div className={styles.coverWrap}>
              {renderImageControls()}
              <img
                src={imgsrc.src}
                onClick={(e) => { e.stopPropagation(); toggleFullscreen(); }}
                alt={imgsrc.title}
                className={styles.coverImage}
              />
              <p style={{ fontWeight: '600' }}>{imgsrc.title}</p>
            </div>
          )}
     
          <div className={styles.thumbDiv}>
            {thumbsArray?.map((img, i) => (
              <div className={styles.thumbdivone} key={i}>
                <img
                  key={img + i}
                  className={determineIndexClass(i)}
                  src={img}
                  alt={
                    languageEn
                      ? `Houses 3D renders Thumbnail ${i}`
                      : `Къщи визуализация миниатюрна ${i}`
                  }
                  onClick={() => handleImageChange(i)}
                />
              </div>
            ))}
          </div>
        </div>

        <div className={styles.rightSec}>
          <div className={styles.startWrap}>
            <h1 className={styles.title}>{currentText.title}</h1>
            <p className={styles.slogan}>
              <i>{currentText.slogan}</i>
            </p>
          </div>

          <h2 className={styles.h2}>
            {currentText.description}
          </h2>

          <p className={styles.describe}>
            {currentText.welcomeText}
          </p>

          {/* <ul className={styles.summary}>
              <li>897 метра над София</li>
              <li>180° Панорамна Гледка </li>
              <li>Чист въздух (10-35 AQI) </li>
              <li>Без шум (5-15 dB) </li>
              <li>Затворен бутиков комплекс</li>
              <li>Размер 650-800 м2, 4 спални, 4.5 бани </li>
              <li>Височина на тавани 3.7м</li>
              <li>Уникална Локация</li>
            </ul> */}

          {/* <p> GPS: 42.59111911191111, 23.38288288288288</p> */}

          <div className={styles.butGroup}>
            <NavHashLink className={styles.bookBut} to="/#contacts">
              {languageEn ? "Visit our location" : "Посетете Мястото"}
            </NavHashLink>

            <a
              href="https://maps.app.goo.gl/VCjbGqQwMN5BRztp7"
              target="_blank"
              rel="noopener noreferrer"
              className={styles.mapLink}
            >
              {languageEn ? "View on Google Map" : "Виж на Google Map"}
            </a>
          </div>
        </div>
      </div>

      <Features languageEn={languageEn} />

      <h4>{currentText.overview}</h4>
      <p className={styles.describe}>
        <p>
          <b> {currentText.bistritsa.title}</b> {currentText.bistritsa.content}
        </p>
        <hr className={styles.hr} />
        <p>
          <b> {currentText.izgledStreet.title}</b> {currentText.izgledStreet.content}
        </p>
        <hr className={styles.hr} />
        <p>
          <b> {currentText.plot.title} </b> {currentText.plot.content}
        </p>
        <hr className={styles.hr} />
        <p>
          <b>{currentText.complex.title}</b> {currentText.complex.content}
        </p>
        <hr className={styles.hr} />
        <p>
          <b>{currentText.yourNewHome.title}</b> {currentText.yourNewHome.content}
        </p>
        <ul>
          {currentText.yourNewHome.features.map((feature, index) => (
            <li key={index}>{feature}</li>
          ))}
        </ul>
        {currentText.yourNewHome.additional}
      </p>

      {/* 
      <img
        className={styles.mapImage} src={map} alt="map of Sofia and project location next to Bistritsa on Izgled street, postcode 1444"></img>
      <img className={styles.house1Image}
        src={house1} alt="large glass modern house cgi frontal diagonal "></img> */}

      {/* carousel */}
      {/* Features */}

      <div id="contacts" style={{ marginBottom: "15px" }}>
        <Contact languageEn={languageEn} />
      </div>
      
      {isFullscreen && (
        <div 
          className={styles.fullscreenImage} 
          onClick={toggleFullscreen}
        >
          <div onClick={(e) => e.stopPropagation()} style={{ position: 'relative' }}>
            {renderImageControls()}
            <img
                            onClick={(e) => { e.stopPropagation(); toggleFullscreen(); }}

              src={imgsrc.src}
              alt={imgsrc.title}
              style={{ width: '100%', height: '100%', objectFit: 'contain' }}
            />
          </div>
        </div>
      )}
    </div>
  );
}