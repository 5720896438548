import React from "react"
import styles from "./Features.module.css"

export default function Features({ languageEn }) {
  const text = {
    en: {
      characteristics: "Characteristics",
      houseDetails: "House Details",
      features: [
        "897 meters above sea level",
        "180° Panoramic view over Sofia",
        "Gated modern complex",
        'Clean air (10-35 AQI)',
        'Silent location (5-15 dB)',
        'Great privacy'
      ],
      houseFeatures: [
        "Built-up area 640-780 m2",
        "4 Bedrooms + 4.5 Bathrooms",
        "Spacious architecture",
        "Extra high 3.7m ceilings",
        "Floor to ceiling windows",
        "Plot 920 m2",
        "4-car garage",
        "Private outdoor BBQ area",
        "Swimming pool",
        "Photovoltaics",
        'Smart home ready',
        'Heat pump'
      ]
    },
    bg: {
      characteristics: "Характеристики",
      houseDetails: "Детайли Къщи",
      features: [
        "897 метра надморска височина",
        "180° Панорамна гледка над София",
        "Затворен модерен комплекс",
        'Чист въздух (10-35 AQI)',
        'Безшумна локация (5-15 dB)',
        'Страхотнo прайвъси'
      ],
      houseFeatures: [
        "Застроена площ 640-780 м2",
        "4 Спални + 4.5 Бани",
        "Просторна архитектура",
        "Екстра високи 3.7м тавани",
        "Прозорци от под до таван",
        "Парцел 920 м2",
        "Гараж за 4 коли",
        "Частна външна BBQ зона",
        "Басейн за плуване",
        "Фотоволтаици",
        'Подготовка за умен дом',
        'Термопомпа'
      ]
    }
  };

  const currentText = languageEn ? text.en : text.bg;

  const bulgarian = {
    phone: "Телeфон 08 777 1444 9",
    address: "Ул. Изглед, Бистрица, София, 1444, България",
  }

  return (
    <div className={styles.featuresWrap}>
      <div className={styles.indivWrap}>
        <h3 className={styles.h2}>
          {currentText.characteristics}</h3>

        <div className={styles.featureMap}>
          {currentText.features?.map((feature, i) => (
            <div key={i} className={styles.featureULItem}>
              <p className={styles.featureP}>+ {feature}</p>
            </div>
          ))}
        </div>
      </div>

      <div className={styles.indivWrap}>
        <h3 className={styles.h2}>
          {currentText.houseDetails}</h3>

        <div className={styles.featureMap}>
          {currentText.houseFeatures?.map((feature, i) => (
            <div key={i} className={styles.featureULItem}>
              <p className={styles.featureP}>+ {feature}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}